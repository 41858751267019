import { configureStore } from "@reduxjs/toolkit";
import stickerSettingsReducer from './stickerSettings/stickerSettingsSlice'

const createStore = () => configureStore({
    reducer: {
        stickerSettings: stickerSettingsReducer
    }
})

type ConfiguredStore = ReturnType<typeof createStore>
type StoreGetState = ConfiguredStore["getState"]
export type RootState = ReturnType<StoreGetState>
export type AppDispatch = ConfiguredStore["dispatch"]

export default createStore