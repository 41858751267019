import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IReaction } from "../../interfaces/IReaction"

// TODO: use redux toolkit for all slices of state, add typescript support:
// (rather involved)
// https://redux-toolkit.js.org/tutorials/typescript

export interface StickerSettingsState {
  avatarImageURL: string
  username: string
  date: string
  labels: Array<string>
  text: string
  reactions: Array<IReaction>
}

export const stickerSettingsSlice = createSlice({
  name: "stickerSettings",
  initialState: {
    avatarImageURL:
      "https://coffee-app.sfo2.cdn.digitaloceanspaces.com/fullstackcraft/logo.jpg",
    username: "FullStackCraft",
    date: "July 1, 2021",
    labels: ["🏘️ Village Idiot"],
    text: "This is where the text content goes!",
    reactions: [
      {
        emoji: "👍",
        count: 236,
      },
      {
        emoji: "😄",
        count: 23,
      },
      {
        emoji: "😕",
        count: 5,
      },
      {
        emoji: "❤️",
        count: 24,
      },
      {
        emoji: "🚀",
        count: 23,
      },
      {
        emoji: "👀",
        count: 27,
      },
    ],
  },
  reducers: {
    avatarImageURLDefined: (
      state,
      action: PayloadAction<{ avatarImageURL: string }>
    ) => {
      state.avatarImageURL = action.payload.avatarImageURL
    },
    usernameDefined: (state, action: PayloadAction<{ username: string }>) => {
      state.username = action.payload.username
    },
    dateDefined: (state, action: PayloadAction<{ date: string }>) => {
      state.date = action.payload.date
    },
    labelsDefined: (
      state,
      action: PayloadAction<{ labels: Array<string> }>
    ) => {
      state.labels = action.payload.labels
    },
    textDefined: (state, action: PayloadAction<{ text: string }>) => {
      state.text = action.payload.text
    },
    reactionsDefined: (
      state,
      action: PayloadAction<{ reactions: Array<IReaction> }>
    ) => {
      state.reactions = action.payload.reactions
    },
  },
})

export const {
  avatarImageURLDefined,
  usernameDefined,
  dateDefined,
  labelsDefined,
  textDefined,
  reactionsDefined,
} = stickerSettingsSlice.actions

export default stickerSettingsSlice.reducer
